@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

#brokers,
#careers,
#executive-leadership {
  scroll-margin-top: 100px;
}
