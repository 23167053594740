.leechiu-blue-bg {
  background-color: #002368;
}

.logo_clip-path {
  background-color: white;
  clip-path: polygon(0 0%, 100% 0, 90% 100%, 0% 100%);
}

.logo_mobile {
  background-color: white;
  text-align: left;
}

.leechiu-blue {
  color: #002368;
}

.leechiu-orange {
  color: #f15a22;
}

.navitem-clickable {
  @apply hover:cursor-pointer text-center px-2 my-2 text-sm;
  
}
